define("cropster-app/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setItem: function setItem(key, data) {
      return window.localStorage.setItem(key, JSON.stringify(data));
    },
    getItem: function getItem(key) {
      return JSON.parse(window.localStorage.getItem(key));
    },
    removeItem: function removeItem(key) {
      return window.localStorage.removeItem(key);
    },
    clear: function clear() {
      window.localStorage.clear();
    }
  });

  _exports.default = _default;
});