define("cropster-app/components/session-review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4IdOmlbm",
    "block": "{\"symbols\":[\"cuppingResult\"],\"statements\":[[7,\"div\"],[11,\"class\",\"layout__cols__container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"cuppingResults\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"layout__col--2\"],[9],[0,\"\\n      \"],[1,[27,\"session-review/item\",null,[[\"cuppingResult\"],[[22,1,[]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/session-review/template.hbs"
    }
  });

  _exports.default = _default;
});