define("cropster-app/components/create-sensorial-session/session-lots/table-lots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e/4G7jts",
    "block": "{\"symbols\":[\"@toggleRecord\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table__global-search\"],[9],[0,\"\\n  \"],[1,[27,\"c-input\",null,[[\"class\",\"value\",\"placeholder\",\"update\"],[\"table__global-search__input\",[23,[\"tmpSearchTerm\"]],[27,\"t\",[\"Type to search...\"],null],[27,\"action\",[[22,0,[]],\"updateSearch\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"searchTerm\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"table__global-search__clear\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[3,\"action\",[[22,0,[]],\"updateSearch\",\"\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[1,[27,\"c-table-paginated/c-table\",null,[[\"data\",\"tableId\",\"columns\",\"enableFiltering\",\"allowToggleRecord\",\"showPageSizeSelect\",\"showColumnsDropdown\",\"pageSize\",\"currentPage\",\"rowComponent\",\"selectedItems\",\"actionHash\"],[[23,[\"data\"]],[23,[\"tableId\"]],[23,[\"columns\"]],false,true,false,false,25,1,\"create-sensorial-session/session-lots/table-lots/row\",[23,[\"selectedLots\"]],[27,\"hash\",null,[[\"toggleRecord\"],[[27,\"action\",[[22,0,[]],[22,1,[]]],null]]]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/create-sensorial-session/session-lots/table-lots/template.hbs"
    }
  });

  _exports.default = _default;
});