define("cropster-app/components/evaluation-form/sample/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZOzhsDxT",
    "block": "{\"symbols\":[\"cuppingResultItem\",\"@updateAssignedSensorialDescriptor\",\"@addSensorialDescriptor\",\"@removeAssignedSensorialDescriptor\",\"@createSensorialDescriptor\",\"@recalculateTotalScore\"],\"statements\":[[4,\"each\",[[23,[\"cuppingResultItems\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"evaluation-form/sample/item\",null,[[\"cuppingResultItem\",\"recalculateTotalScore\",\"createSensorialDescriptor\",\"removeAssignedSensorialDescriptor\",\"addSensorialDescriptor\",\"updateAssignedSensorialDescriptor\"],[[22,1,[]],[22,6,[]],[22,5,[]],[22,4,[]],[22,3,[]],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/sample/group/template.hbs"
    }
  });

  _exports.default = _default;
});