define("cropster-app/components/evaluation-form/inputs/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GtKLcJgY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--centered \",[21,\"titleClass\"]]]],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"c-input\",null,[[\"type\",\"value\",\"placeholder\",\"class\",\"step\",\"min\",\"max\",\"update\",\"blurAction\"],[\"number\",[23,[\"textValue\"]],[27,\"concat\",[[23,[\"min\"]],\" - \",[23,[\"max\"]]],null],[27,\"if\",[[23,[\"textInputHasChanged\"]],\"text--changed\"],null],[23,[\"step\"]],[23,[\"min\"]],[23,[\"max\"]],[27,\"action\",[[22,0,[]],\"updateText\"],null],[27,\"action\",[[22,0,[]],\"updateValue\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/text/template.hbs"
    }
  });

  _exports.default = _default;
});