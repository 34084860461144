define("cropster-app/components/evaluation-form/inputs/special/descriptor-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZjzwLb3Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"button--descriptors button--icon toggle-button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"descriptors-simplified\"],[[\"class\"],[\"icon--inherit-color\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Add descriptors\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"toggleOpen\"]],[10],[0,\"\\n\\n  \"],[1,[27,\"descriptors-list\",null,[[\"assignedSensorialDescriptors\",\"updateAssignedSensorialDescriptor\",\"removeAssignedSensorialDescriptor\",\"allowActions\"],[[23,[\"assignedSensorialDescriptors\"]],[27,\"action\",[[22,0,[]],\"updateAssignedSensorialDescriptor\"],null],[27,\"action\",[[22,0,[]],\"removeAssignedSensorialDescriptor\"],null],true]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"evaluation-form/inputs/special/descriptor-select/select-box\",null,[[\"class\",\"onchange\",\"oncreate\",\"onkeydown\",\"search\",\"showCreateWhen\",\"close\"],[[27,\"unless\",[[23,[\"showAdd\"]],\"is-hidden\"],null],[27,\"perform\",[[23,[\"addSensorialDescriptorTask\"]]],null],[27,\"perform\",[[23,[\"createSensorialDescriptorTask\"]]],null],[27,\"action\",[[22,0,[]],\"checkIfComma\"],null],[27,\"perform\",[[23,[\"searchTask\"]]],null],[27,\"action\",[[22,0,[]],\"showCreateWhen\"],null],[27,\"action\",[[22,0,[]],\"toggleOpen\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/special/descriptor-select/template.hbs"
    }
  });

  _exports.default = _default;
});