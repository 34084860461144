define("cropster-app/components/evaluation-form/inputs/slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X+SxR0v2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title \",[21,\"titleClass\"]]]],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showSlider\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"min-value\"],[9],[0,\"\\n    \"],[1,[21,\"min\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"max-value\"],[9],[0,\"\\n    \"],[1,[21,\"max\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\\n  \"],[7,\"div\"],[11,\"class\",\"current-value\"],[9],[0,\"\\n    \"],[1,[21,\"sliderValue\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"range-slider\",null,[[\"class\",\"direction\",\"behaviour\",\"connect\",\"start\",\"step\",\"min\",\"max\",\"animate\",\"on-start\",\"on-set\",\"on-update\"],[\"slider\",\"ltr\",\"none\",\"lower\",[23,[\"sliderValue\"]],[23,[\"step\"]],[23,[\"min\"]],[23,[\"max\"]],false,[27,\"action\",[[22,0,[]],\"slideStart\"],null],[27,\"action\",[[22,0,[]],\"slideEnd\"],null],[27,\"action\",[[22,0,[]],\"updateValue\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--light\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Add score\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"forceShowSlider\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/slider/template.hbs"
    }
  });

  _exports.default = _default;
});