define("cropster-app/components/system-notifications-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Icu9FYB",
    "block": "{\"symbols\":[\"systemNotification\"],\"statements\":[[4,\"each\",[[23,[\"systemNotifications\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"message message--with-wrapper margin-bottom-small\\n      \",[27,\"if\",[[22,1,[\"isTypeUrgent\"]],\"message--error\"],null],[27,\"if\",[[22,1,[\"isTypeWarning\"]],\"message--warning\"],null]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,1,[\"isTypeUrgent\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"error\"],[[\"class\"],[\"icon--medium\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,1,[\"isTypeWarning\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"warning\"],[[\"class\"],[\"icon--medium\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"info\"],[[\"class\"],[\"icon--medium\"]]],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"message__wrapper\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"message__title\"],[9],[0,\"\\n        \"],[1,[22,1,[\"title\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"format-nl2br\",[[22,1,[\"message\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn--icon message__close\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"images/icons/close.svg\"],[11,\"class\",\"\"],[12,\"alt\",[27,\"t\",[\"Close\"],null]],[9],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"dismissNotification\",[22,1,[]]]],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/system-notifications-list/template.hbs"
    }
  });

  _exports.default = _default;
});