define("cropster-app/components/evaluation-form/inputs/popup-slider-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iMwwNSRi",
    "block": "{\"symbols\":[\"@update\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--centered \",[21,\"titleClass\"]]]],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"touch-slider\",null,[[\"sliderWidth\",\"range\",\"update\",\"value\"],[50,[23,[\"range\"]],[22,1,[]],[23,[\"value\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"item-score \",[27,\"if\",[[23,[\"hasChanged\"]],\"item-score--set\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"is-none\",[[23,[\"value\"]]],null]],null,{\"statements\":[[0,\"      -\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"format-number\",[[23,[\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/popup-slider-small/template.hbs"
    }
  });

  _exports.default = _default;
});