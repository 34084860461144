define("cropster-app/evaluation/overview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h7KFUdsw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-left\"]],{\"statements\":[[4,\"link-to\",[\"evaluation.index\"],[[\"class\"],[\"button button--plain button--icon\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",[\"arrow-right-thin\"],[[\"class\"],[\"icon icon--white transform-rotate-180\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Continue cupping\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-right\"]],{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--plain button--icon\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"check\"],[[\"class\"],[\"icon--white\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Finish & submit\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[27,\"perform\",[[23,[\"submitSessionTask\"]],[23,[\"model\"]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"session-review\",null,[[\"cuppingResults\",\"class\"],[[23,[\"model\"]],\"margin-top-small\"]]],false],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"app-footer\"]],{\"statements\":[[0,\"  \"],[7,\"footer\"],[11,\"class\",\"footer--cupping\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"submitSessionTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/evaluation/overview/template.hbs"
    }
  });

  _exports.default = _default;
});