define("cropster-app/utils/calculate-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calculateStep;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Calculates the step from an array of numbers
   *
   * @class CalculateStep
   * @param array
   * @returns {Number}
   */
  function calculateStep() {
    var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    (false && !(!Ember.isEmpty(arr)) && Ember.assert('array cannot be empty!', !Ember.isEmpty(arr)));

    var _arr2 = _slicedToArray(arr, 2),
        first = _arr2[0],
        second = _arr2[1];

    if (Ember.isNone(second)) {
      second = first;
    }

    var value = Math.abs(Math.abs(first) - Math.abs(second));
    return Math.round(value * 100) / 100;
  }
});