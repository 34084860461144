define("cropster-app/components/evaluation-form/inputs/popup-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/1zAf//",
    "block": "{\"symbols\":[\"@update\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--with-item-score-right \",[21,\"titleClass\"]]]],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"item-score-right\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"is-none\",[[23,[\"value\"]]],null]],null,{\"statements\":[[0,\"    -\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"format-number\",[[23,[\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"popup-slider__container\"],[9],[0,\"\\n\"],[4,\"touch-slider\",null,[[\"sliderWidth\",\"range\",\"update\",\"value\"],[50,[23,[\"range\"]],[22,1,[]],[23,[\"value\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"popup-slider \",[27,\"if\",[[27,\"is-not-none\",[[23,[\"value\"]]],null],\"has-score\"],null]]]],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"currentSliderPosition\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"slider-score\"],[12,\"style\",[21,\"currentSliderPosition\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[27,\"evaluation-form/inputs/popup-slider/background\",null,[[\"steps\",\"class\"],[[23,[\"range\"]],\"slider-background\"]]],false],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/popup-slider/template.hbs"
    }
  });

  _exports.default = _default;
});