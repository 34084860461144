define("cropster-app/components/evaluation-form/inputs/special/descriptor-select/select-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CIXv2CW/",
    "block": "{\"symbols\":[\"sensorialDescriptor\",\"@close\"],\"statements\":[[7,\"div\"],[11,\"class\",\"select-box__input\"],[9],[0,\"\\n\"],[4,\"power-select-multiple-with-create\",null,[[\"searchField\",\"showCreatePosition\",\"placeholder\",\"noMatchesMessage\",\"searchMessage\",\"loadingMessage\",\"verticalPosition\",\"initiallyOpened\",\"allowClear\",\"onchange\",\"onclose\",\"oncreate\",\"onkeydown\",\"search\",\"showCreateWhen\"],[\"name\",\"bottom\",[27,\"t\",[\"Search...\"],null],[27,\"t\",[\"No sensorial descriptors found!\"],null],[27,\"t\",[\"Type to search...\"],null],[27,\"t\",[\"Loading...\"],null],\"below\",[23,[\"initiallyOpened\"]],false,[27,\"action\",[[22,0,[]],\"onchange\"],null],[27,\"action\",[[22,0,[]],\"closeSelect\"],null],[23,[\"oncreate\"]],[23,[\"onkeydown\"]],[23,[\"search\"]],[23,[\"showCreateWhen\"]]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"button button--grey select-box__button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Done\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[22,2,[]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/special/descriptor-select/select-box/template.hbs"
    }
  });

  _exports.default = _default;
});