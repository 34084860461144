define("cropster-app/evaluation/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kgKKc2f6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-left\"]],{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--plain button--icon\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"arrow-right-thin\"],[[\"class\"],[\"icon icon--white transform-rotate-180\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Sessions\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"closeSession\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"nav-bar-right\"]],{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"button button--plain button--icon\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"review\"],[[\"class\",\"viewBox\"],[\"icon icon--white\",\"0 0 30 20\"]]],false],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Review\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"reviewSession\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"evaluation-form\",null,[[\"session\",\"submitSession\"],[[23,[\"model\"]],[27,\"perform\",[[23,[\"submitSessionTask\"]],[23,[\"model\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"submitSessionTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/evaluation/index/template.hbs"
    }
  });

  _exports.default = _default;
});