define("cropster-app/utils/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rangeMiddle = rangeMiddle;
  _exports.rangeMinMax = rangeMinMax;
  _exports.rangeMajorTicks = rangeMajorTicks;
  _exports.default = void 0;

  /**
   * Utils for playing with ranges, aka, arrays of Numbers
   * @module Utils.Range
   */

  /**
   * Compute the middle element of a range.
   * If there is an even number of elements, it returns the right of center element
   * @param {Number[]} range
   * @returns {Number}
   */
  function rangeMiddle(range) {
    (false && !(Ember.isPresent(range)) && Ember.assert('range must be defined', Ember.isPresent(range)));
    (false && !(!Ember.isEmpty(range)) && Ember.assert('range must be non empty', !Ember.isEmpty(range)));
    return range[Math.round((range.length - 1) / 2)];
  }
  /**
   * Compute the min and maximum values for a range
   * @param range
   * @param [formula]
   * @param [itemRegex]
   * @returns {{min: *, max: *}}
   */


  function rangeMinMax(range) {
    var formula = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var itemRegex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    (false && !(Ember.isPresent(range)) && Ember.assert('range must be defined', Ember.isPresent(range)));
    (false && !(!Ember.isEmpty(range)) && Ember.assert('range must be non empty', !Ember.isEmpty(range)));
    var maxValue = range.get('lastObject');
    var minValue = range.get('firstObject');
    /* eslint-disable no-useless-escape */

    if (Ember.isPresent(formula)) {
      (false && !(Ember.isPresent(itemRegex)) && Ember.assert('If a formula is supplied, then the item regex must also be', Ember.isPresent(itemRegex)));
      var mathString = formula.replace(itemRegex, maxValue); // check that only allowed symbols are in math string

      var re = /[^0-9|\(|\)|\-|\*|\+|\/|\.]/g;

      if (!re.test(mathString)) {
        maxValue = eval(mathString);
      }

      mathString = formula.replace(itemRegex, minValue); // check that only allowed symbols are in math string

      re = /[^0-9|\(|\)|\-|\*|\+|\/|\.]/g;

      if (!re.test(mathString)) {
        minValue = eval(mathString);
      }
    }
    /* eslint-enable no-useless-escape */


    var isInverted = maxValue < minValue;
    return {
      min: isInverted ? maxValue : minValue,
      max: isInverted ? minValue : maxValue
    };
  }
  /**
   * Compute the major tickValues of a given range and step.
   *
   * @param range
   * @param step
   * @return {Number[]}
   */


  function rangeMajorTicks(range, step) {
    var major = [];

    if (step === 0.25) {
      major.push(range[0]);

      for (var i = 1; i < range.length; ++i) {
        if (i % 4 === 0) {
          major.push(range[i]);
        }
      }
    } else if (step === 0.5) {
      major.push(range[0]);

      for (var _i = 1; _i < range.length; ++_i) {
        if (_i % 2 === 0) {
          major.push(range[_i]);
        }
      }
    } else {
      major = range;
    } // Ensure there are never more than 10 major ticks


    var maxMajorCount = 10;
    var majorCount = major.length;

    if (majorCount > maxMajorCount) {
      var newMajor = [];
      var split = 2;

      while (majorCount / split > maxMajorCount) {
        split++;
      }

      for (var _i2 = 0; _i2 < majorCount; _i2 += split) {
        newMajor.push(major[_i2]);
      }

      major = newMajor;
    }

    return major;
  }

  var _default = {
    rangeMinMax: rangeMinMax,
    rangeMiddle: rangeMiddle
  };
  _exports.default = _default;
});