define("cropster-app/components/evaluation-form/sample/roast-levels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eLP3KctW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"roast-title \",[27,\"if\",[[23,[\"isVertical\"]],\"roast-title--vertical\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Roast\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"roast-levels \",[27,\"if\",[[23,[\"isVertical\"]],\"roast-levels--vertical\"],null],\" \",[27,\"if\",[[23,[\"sliderValueHigh\"]],\"roast-levels--high\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isVertical\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"range-slider\",null,[[\"min\",\"max\",\"behaviour\",\"on-set\",\"on-slide\",\"start\",\"orientation\",\"step\"],[[23,[\"roastLevels\",\"firstObject\"]],[23,[\"roastLevels\",\"lastObject\"]],\"tap\",[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"action\",[[22,0,[]],\"onSlide\"],null],[23,[\"actualValue\"]],\"vertical\",1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"range-slider\",null,[[\"min\",\"max\",\"behaviour\",\"on-set\",\"on-slide\",\"start\",\"orientation\",\"step\"],[[23,[\"roastLevels\",\"firstObject\"]],[23,[\"roastLevels\",\"lastObject\"]],\"tap\",[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"action\",[[22,0,[]],\"onSlide\"],null],[23,[\"actualValue\"]],\"horizontal\",1]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/sample/roast-levels/template.hbs"
    }
  });

  _exports.default = _default;
});