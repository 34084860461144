define("cropster-app/components/evaluation-form/inputs/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rBx4GaZv",
    "block": "{\"symbols\":[\"currentValue\",\"val\",\"@update\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"title title--centered \",[21,\"titleClass\"]]]],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"c-select\",null,[[\"value\",\"update\"],[[23,[\"value\"]],[22,3,[]]]],{\"statements\":[[0,\"  \"],[7,\"option\"],[11,\"value\",\"\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Select...\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"range\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,2,[]]],[12,\"selected\",[27,\"eq\",[[22,2,[]],[22,1,[]]],null]],[9],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/evaluation-form/inputs/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});