define("cropster-app/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f3DibZBM",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"login-form\",null,[[\"login\"],[[27,\"action\",[[22,0,[]],[22,0,[\"login\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/login/template.hbs"
    }
  });

  _exports.default = _default;
});