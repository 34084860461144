define("cropster-app/components/sessions-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LEM688PV",
    "block": "{\"symbols\":[\"group\",\"session\",\"@sessionClicked\"],\"statements\":[[4,\"each\",[[23,[\"groupedSessions\"]]],null,{\"statements\":[[4,\"unless\",[[23,[\"disableDateGrouping\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"date\"],[9],[0,\"\\n      \"],[1,[22,1,[\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"item-list\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"contents\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[1,[27,\"sessions-list/item\",null,[[\"session\",\"class\",\"clicked\"],[[22,2,[]],\"item-list__item\",[27,\"action\",[[22,0,[]],[22,3,[]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cropster-app/components/sessions-list/template.hbs"
    }
  });

  _exports.default = _default;
});